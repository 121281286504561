<template>
  <div>
    <Header />
    <ion-content>
      {{ message }}
    </ion-content>
  </div>
</template>

<script>
    import { CONNECT_TO_STRIPE } from '@/graphql/mutations'

    export default {
      data() {
        return {
          code: this.$router.query.code,
          state: this.$router.query.state,
          message: 'Een ogenblik geduld, we zijn bezig om Stripe te connecten we sturen je zo terug, naar waar je vandaan kwam.'
        }
      },
      created() {
        if(!this.code && this.state) {
            this.message = 'Er ging iets mis met verbinden met Stripe, we sturen u terug naar de pagina waar u vandaan kwam.';

            setTimeout(() => {
              this.$router.push({ path: this.state });
            }, 5000)
        }

        if(!this.code && !this.state) {
          this.message = 'Er ging iets mis met verbinden met Stripe, we sturen u terug naar de homepagina.';

          setTimeout(() => {
            this.$router.push({ path: this.state });
          }, 5000)
        }

        this.$apollo.mutate({
          mutation: CONNECT_TO_STRIPE,
          variables: {
            code: this.code,
            state: this.state
          }
        }).then(() => {
          this.$router.push({ path: this.state })
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
      },
      apollo: {

      }
    }
</script>
